.documents-card {
    max-width: 495px !important;
    width: 50%;
    background-color: #ffff;
    padding: 20px 30px 20px 20px;
    box-shadow: 0px 0px 7px #a7a7a7;
    margin-bottom: 30px;
    height: 80vh;
}

.home-card {
    margin-left: 12px;
    margin-right: 13px;
}

.office-card {
    margin-left: 13px;
    margin-right: 13px;
}

.inner-card-form {
    margin-left: 75px;
}

/*.intakeform-download-icon {*/
/*  padding-right: 6px;*/
/*  display: grid;*/
/*  justify-content: flex-end;*/
/*}*/

.close-icon {
    font-size: 20px;
    cursor: pointer;
    margin: auto 0;
}

.submit-icon {
    position: absolute;
    top: 0px;
}

.search-id-input {
    /*position: absolute;*/
    /*top: 9px;*/
    width: 184px !important;
    letter-spacing: -0.5px;
    height: calc(1.5em + 0.75rem + 4px);
    font-size: 14px;
    font-weight: 400;
    line-height: 1.5;
    color: #495057;
    background-color: #fff;
    background-clip: padding-box;
    transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    padding: 7px 8px;
    border-radius: 0;
    margin-right: 12px;
}

.search-id-input::placeholder {
    color: #a7a7a7 !important;
}

.received-msg {
    height: 50vh !important;
}

.flex-container-big-small {
    display: flex;
    flex-direction: row;
    /*padding: 1px;*/
}

.flex-container-big {
    flex-grow: 2;
}

.div-input-with-button {
    border: 1px solid #94bbad;
    padding: 1px;
}

.div-input-with-button:focus-within {
    outline: 2px solid #94bbad;
}

.input-with-button {
    border: none;
}

.input-with-button:focus {
    outline: none;
    border: none;
}

.button-in-input {

}

.clear-btn {
    background: white;
    border: none;
    color: #a7a7a7;
    height: 24px;
    font-family: Lato;
    font-size: 14px;
    font-style: italic;
    letter-spacing: -0.5px;
    line-height: 21px;
    text-align: right;
    transform: translateX(-2px);
}

.message-received-date {
    margin-right: 6px;
    color: #232323;
    font-family: Lato;
    font-size: 12px;
    font-style: italic;
    letter-spacing: 0;
    line-height: 15px;
}

.message-received-time {
    margin-left: 6px;
    color: #232323;
    font-family: Lato;
    font-size: 12px;
    font-style: italic;
    letter-spacing: 0;
    line-height: 15px;
}

.msg-margin-left {
    margin-left: 8px;
}

.received-msg-content {
    height: 100px;
}

.arrow-icon {
    height: 19px;
    width: 15px;
    color: #a7a7a7;
    font-family: "SS Gizmo";
    font-size: 14px;
    letter-spacing: 0;
    line-height: 19px;
    text-align: center;
    margin: 0 5px;
}

.office-text {
    color: #a7a7a7;
}

.userid-text {
    color: #232323;
    font-family: Lato;
    font-size: 14px;
    letter-spacing: 0;
    line-height: 19px;
}

.card-inner-drag-drop {
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 86px;
    background-color: #fff;
    border: 1px dashed #979797;
    box-sizing: border-box;
}

.card-head-large-text {
    font-size: 20px;
    line-height: 24px;
    font-style: normal;
    color: #232323;
    border-bottom: 0.5px solid #e3e4e8;
}

.card-hoiz-text {
    margin: 12px 0px;
}

.card-head-font {
    font-size: 20px;
}

.card-inner-text-font {
    font-size: 16px;
}

.payer-name-font {
    font-size: 12px;
    width: 90px;
}

.card-inner-text-font-payer {

    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
}

.card-inner-text-font-payer:hover {
    text-overflow: unset;
    white-space: initial;
    overflow: unset;
}

.card-head-right-font {
    font-size: 14px;
}

.card-drag-drop-p {
    width: 310px;
    color: #232323;
    font-family: Lato;
    font-size: 15px;
    letter-spacing: 0;
    line-height: 15px;
    text-align: center;
}

.document-img {
    position: absolute;
    top: 70px;
    left: 0;
}

.intakeform {
    max-width: 1107px;
    width: 100%;
    margin: auto;
    background-color: #ffff;
    box-shadow: 1px 2px 23px 0 #a7a7a7;
    overflow: hidden;
}

.intake-content-admin {
    padding-top: 20px !important;
    background-color: #f5f6fa;
}

.intake-content-admin-color {
    background-color: #f5f6fa;
}

/* <-----------------------------------> */

.header-card {
    margin: 0 auto;
    background-color: #ffff;
    padding: 20px 30px 20px 20px;
    box-shadow: 0px 0px 1px #A7A7A7;
}

.home-header-card {
    width: 100%;
    margin: 0 auto;
    background-color: #ffff;
    padding: 20px 30px 20px 20px;
    box-shadow: 0px 0px 7px #A7A7A7;
    margin-bottom: 45px;
}

.head-line {
    font-size: 22px;
    font-weight: 400;
}

.header-label {
    width: 100%;
    height: calc(1.5em + 0.75rem + 4px);
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    color: #495057;
    background-color: #fff;
    background-clip: padding-box;
    transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    padding: 7px 8px;
    border-radius: 0;
    margin-right: 12px;
}

.home-icon {
    border-right: 1px solid #adb5bd;
    margin-right: 10px;
}

.navbar {
    margin-right: 25%;
    height: 66px;
}

.nav-list-item a {
    display: block;
    color: white;
    text-decoration: none;
}

.nav-list-item a:hover {
    border-bottom: 3px solid #6e86c4;
}

.nav-list-selected {
    border-bottom: 3px solid #6e86c4;
}

.office-label {
    width: 100%;
    height: calc(1.5em + 0.75rem + 4px);
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    color: #495057;
    background-color: #fff;
    background-clip: padding-box;
    transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    padding: 7px 8px;
    border-radius: 0;
}

.head-font {
    font-size: 30px;
}

.office-form-label {
    font-family: "Lato";
    letter-spacing: 0;
    font-weight: 500 !important;
    font-size: 20px;
}

.drag-sub-div-dash {
    position: relative;
    padding-top: 25% !important;
}

.drag-sub-div-dash-without {
    position: relative;
    padding-top: 0;
}

.input-margin {
    margin: 0px 20px;
}

.input-margin-inner {
    margin: 0px 0px 0px 20px;
}

.color-picker-block {
    width: 100% !important;
    max-width: 185px !important;
    margin: 0 auto;
}

.colorchoice.d-flex {
    margin-bottom: 12px;
}

.upload-doc-scroll::-webkit-scrollbar {
    -webkit-appearance: none;
    width: 8px;
}

.upload-doc-scroll::-webkit-scrollbar-thumb {
    border-radius: 4px;
    background-color: rgba(0, 0, 0, 0.5);
    --webkit-box-shadow: 0 0 1px rgba(255, 255, 255, 0.5);
}

.upload-doc-scroll {
    overflow-y: scroll;
    overflow-x: hidden;
    overflow-wrap: break-word;
    height: 224px;
}

.upload-intake-scroll {
    height: 200px;
    margin-left: -40px;
    margin-right: -20px;
}

.usefull-document-scroll {
    height: 275px;
}

.colorchoice.d-flex {
    margin-bottom: 12px;
}

.colorpicker-input {
    width: 80%;
    margin-left: 27px;
    margin-top: 100px;
    height: calc(1.5em + 0.75rem + 4px);
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    color: #495057;
    background-color: #fff;
    background-clip: padding-box;
    transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    padding: 7px 8px;
    border-radius: 0;
}

.payment-amount-style {
    margin-bottom: 14px;
}

li.nav-item.custom-tab {
    max-width: 206px;
    box-shadow: 0px 0px 0px 1px #e0e0e0;
    background-color: #ffffff;
}

.popover.popover--topright .arrow {
    left: 88% !important;
}

.popover.popover--topright {
    margin-right: 30px;
}

.payerinfo {
    display: flex;
}

.max-size {
    text-align: center;
    padding-top: 5px;
    font-family: Lato;
    font-size: 15px;
}

.jumbotron {
    padding: 0 48px 60px 48px;
}

.header-icon {
    margin-left: 10px;
    height: 50px;
    padding: 0px !important;
    margin-right: 20px;
}

.card-head-logo-font {
    font-size: 16px;
    font-weight: normal;
}

.img-fluid {
    width: 248px;
    max-height: 53px;
    object-fit: contain;
}

.upload-Logo-Name {
    width: 90%;
    margin: auto;
    word-break: break-word;
    height: 50px;
    white-space: break-spaces;
    overflow: hidden;
    text-overflow: ellipsis;
}

.logoname-display {
    padding-top: 10px !important;
}

.empty-amount-due {
    height: 200px !important;
}

.patientId {
    color: #232323;
    font-size: 34px;
    font-weight: bold;
    letter-spacing: 0;
    line-height: 41px;
    margin-left: 28px;
}

.confirmation-email {
    font-size: 18px;
    margin-left: 10%;
}

.empty-amount-due {
    height: 200px !important;
}

.office-contact-info {
    font-size: 18px;
    font-family: lato;
}

.content-center {
    display: flex;
    align-items: center;
}

.recent-transaction-card {
    height: 730px;
    width: 100%;
    background-color: #ffffff;
    box-shadow: 1px 1px 6px 0 #a7a7a7;
    margin: 0 auto;
    padding: 20px 30px 20px 20px;
}

.transaction-details {
    height: 460px;
    overflow-x: hidden;
    overflow-y: auto;
    position: relative;
}

.payment-date {
    color: #232323;
    font-family: Lato;
    font-size: 12px;
    font-style: italic;
    letter-spacing: 0;
    line-height: 15px;
}

.transaction-data {
    color: #232323;
    font-family: Lato;
    font-size: 16px;
    font-weight: normal;
    letter-spacing: 0;
    line-height: 19px;
}

.transaction-id {
    font-size: 10px !important;
}

.search-specific-transaction {
    color: #232323;
    font-family: Lato;
    font-size: 20px;
    letter-spacing: 0;
    line-height: 24px;
}

.by-patient-id-payer {
    max-width: 371px;
    color: #000000;
    font-family: Lato;
    font-size: 14px;
    font-style: italic;
    letter-spacing: 0;
    line-height: 20px;
}

.search-transaction-input {
    letter-spacing: -0.5px;
    height: calc(1.5em + 0.75rem + 4px);
    font-size: 14px;
    font-weight: 400;
    line-height: 1.5;
    color: #495057;
    background-color: #fff;
    background-clip: padding-box;
    transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    padding: 7px 8px;
    border-radius: 0;
    margin-right: 12px;
}

.transaction-clear-btn {
    border-radius: 3px;
    background: white;
    border: none;
    bottom: 1px;
    z-index: 2;
    right: 55px;
    color: #a7a7a7;
    height: 27px;
    width: 37px;
    font-family: Lato;
    font-size: 14px;
    font-style: italic;
    letter-spacing: -0.5px;
    line-height: 28px;
    text-align: right;
    transform: translateX(2px);
}

.transaction-amount {
    padding-right: 70px !important;
}

.transaction-header-icon {
    margin-left: 10px;
    padding: 0px !important;
    margin-right: 20px;
}

.transaction-header {
    color: #232323;
    font-family: Lato;
    font-size: 20px;
    letter-spacing: 0;
    line-height: 24px;
    padding-right: 35px;
}

.empty-documents {
    height: 300px;
    color: #232323;
    font-family: Lato;
    font-size: 16px;
    line-height: 20px;
    text-align: center;
    display: grid;
    padding: 0px 40px 0px 40px;
    align-items: center;
    width: 260px;
    margin: auto;
}

.display-msg {
    position: absolute;
    top: 40%;
    left: 37%;
}

.loader-center {
    position: relative;
    top: 300%;
}

.officesetup-select {
    display: block;
    width: 100%;
    padding: 0.375rem 0.75rem;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    color: #212529;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid #ced4da;
    -webkit-appearance: none;
    appearance: none;
    border-radius: 0.25rem;
    transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

.timeZone-dropdown {
    position: relative;
}

.timeZoneDown-arrow {
    width: 12px;
    position: absolute;
    top: 37px;
    right: 25px;
}

.custom-input-border-css {
    border: 1px solid #94bbad;
}

.officesetup-select {
    padding-right: 30px !important;
}

@media only screen and (max-width: 990px) {
    .office-content-block {
        width: 100%;
        margin: 0 auto;
        background-color: #ffff;
        padding: 20px 30px 20px 20px;
        box-shadow: 0px 0px 7px #a7a7a7;
        margin-bottom: 45px;
        margin-top: -5%;
    }
}

@media only screen and (max-width: 900px) {
    .navbar {
        margin-right: auto;
    }

    .input-margin {
        margin: auto;
    }

    .input-margin-inner {
        margin: auto;
    }
}

@media only screen and (max-width: 770px) {
    .center-content {
        margin: auto !important;
        margin-top: 30px !important;
    }

    .center-message {
        margin: auto;
    }

    .center-btn {
        display: flex;
        justify-content: center;
        margin-top: 30px;
    }

    .edit-payment-header {
        padding-left: 50px;
    }
}

@media only screen and (max-width: 700px) {
    .text-cerulean {
        top: 81px;
        right: 14px;
    }

    .home-icon {
        border-right: none;
    }

    .nav-link {
        padding: 5px !important;
    }

    .navbar {
        position: absolute;
    }

    .icon.navbar {
        position: absolute;
        top: 60px;
        right: 77px;
    }

    .documents-card {
        width: auto;
    }
}

@media (max-width: 575px) {
    .search-id-input {
        position: absolute;
        top: 7px;
        width: 151px !important;
        right: 0px;
    }

    .clear-btn {
        top: 13px;
        right: 19px;
    }

    .received-msg-content {
        height: 120px;
    }

    .payerinfo {
        display: block;
    }

    .confirmation-email {
        margin-left: 35px;
    }
}

@media only screen and (max-width: 1100px) and (min-width: 450px) {
    .center-content {
        margin: auto !important;
        margin-top: 30px !important;
    }

    .center-message {
        margin: auto;
        margin-top: 30px !important;
    }

    .documents-card {
        max-width: 440px !important;
    }

    .search-id-input {
        width: 160px !important;
    }

    .documents-card {
        margin-bottom: 0px !important;
    }

    .save-btn {
        margin-top: 30px;
    }

    .nav-transaction {
        justify-content: flex-start !important;
    }

    .confirmation-email {
        margin-left: 37px;
    }
}

@media only screen and (max-width: 1100px) and (min-width: 576px) {
    .clear-btn {
        right: 31px !important;
    }
}

@media (max-width: 420px) {
    .navbar {
        margin-right: auto;
    }

    .intakeform {
        padding: 14px;
    }

    .header-label {
        width: 100%;
    }

    .search-id-input {
        position: absolute;
        top: 7px;
        width: 110px !important;
        right: 0px;
    }

    .clear-btn {
        top: 13px;
        right: 18px;
        line-height: 24px;
    }

    .document-img {
        position: absolute;
        top: 80px;
        left: 0;
    }

    .submit-icon {
        position: absolute;
        top: 0px;
        right: 2px;
    }

    .received-msg-content {
        height: 141px;
    }

    .payerinfo {
        display: block;
    }

    .confirmation-email {
        margin-left: 35px;
    }

    .received-msg-img {
        top: 90px;
    }
}

@media (max-width: 320px) {
    .navbar {
        margin-right: auto;
    }

    .intakeform {
        padding: 14px;
    }

    .payerinfo {
        display: block;
    }

    .confirmation-email {
        margin-left: 30px;
    }

    .header-label {
        font-size: 13px;
    }
}

@media only screen and (min-width: 320px) and (max-width: 425px) {
    .card-drag-drop-p {
        width: 190px !important;
    }
}

@media only screen and (min-width: 320px) {
    .search-transaction-input {
        width: 200px !important;
    }

    .transaction-clear-btn {
        position: initial;
        margin-left: -55px;
    }
}

.loader-helloSign {
    position: fixed;
    top: 50%;
    left: 50%;
    z-index: 999999;
}

.margin-left-ten {
    margin-left: 1em;
}

.margin-left-five {
    margin-left: 0.3em;
}

.align-icons {
    margin-bottom: 0px;
    padding-top: 4px;
}

.chrome-picker {
    box-shadow: none !important;
}


.min-height-100-vh {
    min-height: 100vh;
}

.swipe-credit-card-icon svg {
    width: 31px;
    height: 21px;
}


.cross-btn {
    position: absolute;
    right: 8px;
    top: 0;
    margin: 8px;
    cursor: pointer;
    z-index: 999;
}

.bg-white {
    background: white;
}
