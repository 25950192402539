/* .theme-input {
  padding-left: 30px !important;
} */
.color-pic {
  padding: 10px;
  width: 10px !important;
  position: absolute;
  border-radius: 30px;
  border: 1px solid rgb(231, 235, 238);
  right: 20px;
  top: 7px;
}
.popOver-theme .popover-header {
  padding: 0px 0px 10px 0px !important;
  font-size: 14px !important;
  line-height: 17px;
  margin: 0px 0px 0px 9px;
}
