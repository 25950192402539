.pay-card {
  max-width: 270px;
  width: 100%;
  margin: 0 auto;
  background-color: #ffff;
  padding: 20px 30px 20px 20px;
  box-shadow: 1px 1px 6px 0 #a7a7a7;
  margin-bottom: 30px;
  height: max-content;
  margin-right: 30px;
  margin-left: 30px;
}
.inner-card-form {
  margin-left: 40px;
}
/*.intakeform-download-icon {*/
/*  display: grid;*/
/*  justify-content: flex-end;*/
/*}*/
​ .close-icon {
  font-size: 20px;
  cursor: pointer;
}
.card-inner-drag-drop {
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 86px;
  background-color: #fff;
  border: 1px dashed #979797;
  box-sizing: border-box;
}
.card-head-large-text {
  font-size: 20px;
  line-height: 24px;
  font-style: normal;
  color: #232323;
  border-bottom: 0.5px solid #e3e4e8;
}
.card-hoiz-text {
  margin: 22px 0px;
}
.card-head-font {
  font-size: 20px;
}
.card-inner-text-font {
  font-size: 16px;
}
​ .card-head-right-font {
  font-size: 14px;
}
.card-drag-drop-p {
  width: 310px;
  color: #232323;
  font-family: Lato;
  font-size: 15px;
  letter-spacing: 0;
  line-height: 15px;
  text-align: center;
}
​ .document-img {
  position: absolute;
  top: 60px;
  margin-left: -13px;
}
​ .intakeform {
  max-width: 1107px;
  width: 100%;
  margin: auto;
  background-color: #ffff;
  padding: 15px 40px 40px 40px;
}
.intake-content {
  padding-top: 50px;
}
​
  /* <-----------------------------------> */
  ​
  .header-card {
  margin: 0 auto;
  background-color: #ffff;
  padding: 20px 30px 20px 20px;
  box-shadow: 0px 0px 1px #a7a7a7;
  margin-bottom: 30px;
}

.nav-list-item a {
  display: block;
  color: white;
  text-decoration: none;
}
​ .nav-list-item a:hover {
  border-bottom: 3px solid #6e86c4;
}
.office-label {
  height: calc(1.5em + 0.75rem + 4px);
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  background-color: #fff;
  background-clip: padding-box;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  padding: 7px 8px;
  border-radius: 0;
}
.head-font {
  font-size: 30px;
}
.office-form-label {
  font-family: "Lato";
  letter-spacing: 0;
  font-weight: 500 !important;
  font-size: 20px;
}
.drag-sub-div {
  position: relative;
  padding-top: 40%;
}

.pay-large-inner p {
  padding-left: 10px;
  /* font-size: 16px; */
  color: #232323;
  line-height: 19px;
  /* font-weight: bold; */
  margin-bottom: 0rem;
}
.pay-left {
  width: 25%;
  padding-right: 10px;
  align-self: start;
}
.pay-center {
  margin-left: 5px;
  width: 35%;
  padding: 0 0.75rem;
  align-self: start;
}
.pay-account-balance {
  width: 40%;
  padding-left: 0px;
  padding-right: 10px;
  /* font-size: 12px; */
  color: #232323;
  line-height: 19px;
  /* font-weight: bold; */
  margin-bottom: 0rem;
}
.pay-control {
  display: block;
  width: 40%;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #212529;
  background-color: #fff;
  background-clip: padding-box;
  -webkit-appearance: none;
  appearance: none;
  border-radius: 0.25rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
.pay-custom-btn {
  font-size: 14px;
  line-height: 17px;
  font-weight: normal;
  color: #fff;
  background-color: #6e86c4;
  border: 1px solid #6e86c4;
  padding: 11px 20px;
  width: 100%;
  transition: 0.5s;
}
.pay-large-inner-text p {
  font-size: 21px;
  padding-left: 12px;
}

.pay-inner-text p {
  color: #232323;
  font-size: 34px;
  font-weight: bold;
  letter-spacing: 0;
  line-height: 41px;
  margin-left: 60px;
}
.pay-saved-method {
  margin-left: 60px;
}
.pay-toggleable {
  padding-top: 12px;
}
.pay-patient-style {
  color: #232323;
  font-size: 20px;
  letter-spacing: 0;
  line-height: 24px;
}
.select-payment {
  margin-left: 38px;
}
.payment-style {
  vertical-align: middle;
  font-size: 14px;
  font-family: Lato;
}
.homehealth-card {
  margin: 0px 20px;
  margin-right: 30px;
}
.payment-raound {
  width: 100%;
  max-width: 61px;
  border-radius: 50%;
  height: 61px;
  max-height: 61px;
  text-align: center;
  border-radius: 50px;
  font-size: 20px;
  color: #6e86c4;
  background-color: #ffffff;
  border: 1px solid #d8d8d8;
  margin-bottom: 13px;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1;
}
.payment-raound-secondary {
  height: 61px;
  font-size: 20px;
  margin-bottom: 13px;
}

@media only screen and (max-width: 900px) {
  .intake-content-inner {
    display: block;
  }
  .pay-card {
    margin: 0 auto 30px;
  }
  .intake-content-box {
    width: 100% !important;
  }
  .payment-patient-inner {
    max-width: 750px;
    margin: 0 auto !important;
  }
}
@media only screen and (min-width: 899px) {
  .intake-content-inner {
    display: flex;
  }
}
@media only screen and (max-width: 430px) {
  .payment-patient-inner {
    max-width: 395px;
    margin-right: 15px !important;
  }
  .received-msg-inner-card {
    margin-left: 55px;
  }
  .pay-saved-method {
    margin-left: 35px;
  }
}
