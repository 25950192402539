.custom-checkbox .hide {
  display: none;
}

.custom-checkbox {
  box-sizing: border-box;
  height: 17px;
  width: 17px;
  border: 1px solid #6e86c4;
  border-radius: 1px;
  cursor: pointer;
  transform: translate(-50%, -50%);
  /* margin-left: 20px; */
  /* display: block; */
  right: 0px;
  top: 17px;
  position: relative;
  font-size: 22px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.custom-checkbox input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

.custom-checkbox-checked {
  /* position: absolute; */
  top: 0;
  left: 0;
  height: 17px;
  width: 17px;
  background-color: white;
}

.custom-checkbox:hover input ~ .custom-checkbox-checked {
  background-color: #ccc;
}
.custom-checkbox input:checked ~ .custom-checkbox-checked {
  background-color: #2196f3;
}
.custom-checkbox-checked:after {
  content: "";
  position: absolute;
  display: none;
}
.custom-checkbox input:checked ~ .custom-checkbox-checked:after {
  display: block;
}
.custom-checkbox .custom-checkbox-checked:after {
  left: 5px;
  top: 1px;
  width: 6px;
  height: 12px;
  border: solid #6e86c4;
  border-width: 0 3px 3px 0;
  transform: rotate(45deg);
}

.custom-checkbox .hide {
  display: none;
}
