zdvss.reply-header {
  margin-left: 17px;
}
.msg-input {
  margin-right: 35px;
}
.send-btn {
  width: 121px !important;
}
.intake_label_m {
  margin: 10px 28px;
}
.toast-intake {
  display: flex;
  justify-content: center;
  align-items: center;
}
.error-intake {
  padding-left: 23px !important;
  color: red;
}
.error-intake-email {
  color: red;
}
.send-intake-msg {
  z-index: 1;
}
