.fa-fa-icon {
    font-size: medium;
    border-radius: 3px;
    padding: 5px;
    color: whitesmoke;
}

.tablepagination {
    /*border: 1px solid #242e42;*/
    padding: 5px 0;
    /*background-color: #242e42;*/
    /*background-color: #6E86C4;*/
    /*background-color: #c5cee7;*/
    background-color: #e9ecf6;
    color: black;
    /*border-radius: 0 0 10px 10px;*/
}

.zui-table {
    border-collapse: collapse;
    border-spacing: 0;
    /*font: bold 13px sans-serif;*/
    font: 14px Lato;
    /*text-align: center;*/
    width: 100%;
}

.zui-table thead th {
    /*background-color: #242e42;*/
    background-color: #FFFFFF;
    color: black;
    /*color: whitesmoke;*/
    /*padding: 10px;*/
    padding: 2px;
    font-weight: 400;
    font-size: 18px;
    line-height: 21px;


}

.zui-table tbody td {
    /*padding: 10px;*/
    padding: 2px;
}

.zui-table-rounded {
    border: snow;
}

.zui-table-rounded thead th {
    padding-top: 6px;
    padding-bottom: 6px;
    /*background-color: #242e42;*/
    /*background-color: #BCD7CE;*/
    /*background-color: #6E86C4;*/
    /*color: whitesmoke;*/
}

.zui-table-rounded thead th:first-child {
    /*border-radius: 10px 0 0 0;*/
}

.zui-table-rounded thead th:last-child {
    /*border-radius: 0 10px 0 0;*/
}

.zui-table-rounded tbody tr:last-child td:first-child {
    /*border-radius: 0 0 0 10px;*/
}

.zui-table-rounded tbody tr:last-child td:last-child {
    /*border-radius: 0 0 0 0;*/
}

.table-font-size {
    font-size: 12px;
}

.delete-button {
    color: #6e86c4;
    font-weight: normal;
    font-size: 22px;
    line-height: 24px;
    padding-right: 10px;
}


tr.payer-table:nth-child(2n) {
    background-color: #ffffff;
}

tr.payer-table:hover {
    /*background-color: #adcbc1;*/
    /*background-color: #cddfd9;*/
    background-color: #deeae6;
}

/*thead > tr > th > div {*/
/*    width: initial;*/
/*}*/

tr > td {
    word-wrap: break-word;
}

.color-black {
    /*color: #232323;*/
    color: #656565;
}

.react-table-filter-inputs {
    height: calc(1.5em );
}
