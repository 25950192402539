.modal-btn {
    font-family: "Lato";
    font-size: 14px;
    line-height: 17px;
    font-weight: normal;
    color: #fff;
    background-color: #6e86c4;
    border: 1px solid #6e86c4;
    padding: 11px 15px;
    min-width: 100px;
    width: 100%;
}

.modal-headr-text {
    font-family: "Lato";
    text-align: center;
    padding: 48px 0px;
    font-size: 20px;
}

.modal-headr-text-style {
    color: #232323;
    text-align: center;
    padding: 48px 0px;
    font-size: 25px;
    line-height: 24px;
    font-style: normal;
}

.modal-headr-text h3 {
    font-size: 34px;
    color: #232323;
    font-weight: bold;
}

.modal-headr-text p {
    font-size: 20px;
    color: #232323;
    font-weight: normal;
}

label.label-check.font-weight-light {
    font-size: 16px;
}

.nav-item .active {
    background-color: #ffffff !important;
    transition: all 0.5s ease;
    color: #000 !important;
}

.pad-ul-li {
    margin-right: 4px !important;
}

.home-link {
    margin-bottom: 4px !important;
}

.success-payment .modal-header {
    border-bottom: none;
}

.dropdown-toggle::after {
    margin: 10px !important;
}

.payment-checbox {
    margin-left: auto;
    width: max-content;
    display: grid;
    padding-right: 15%;
}

.or-line {
    height: 1px;
    width: 100%;
    border: 0.5px solid #8ca0b3;
    opacity: 0.4;
    position: absolute;
    top: 30px;
}

.pay-saved-inner-block {
    position: relative;
    margin: 25px 0px 13px 0px;
    display: flex;
    justify-content: center;
}

.success-payment .modal-dialog {
    max-width: 794px !important;
    width: 100% !important;
}

.select-pay-method {
    margin-left: 40px;
}

@media only screen and (max-width: 1024px) {
    .success-payment .modal-dialog {
        width: 96% !important;
    }
}

@media only screen and (max-width: 767px) {
    .navbar-nav {
        display: contents !important;
    }

    .hommeexisting-large-text p {
        font-size: 16px;
    }

    label.label-check.font-weight-light {
        font-size: 14px;
    }

    .modal-headr-text h3 {
        font-size: 29px;
    }

    .modal-headr-text p {
        font-style: 8px;
    }

    .home-link {
        margin-top: 30px;
    }
}

.close-modal-btn {
    position: absolute;
    right: 0;
    margin: 18px;
    cursor: pointer;
    z-index: 999;
}

.down-arrow {
    font-size: 16px;
    width: 15px;
    position: absolute;
    top: 9px;
    right: 10px;
}

.edit-card-info {
    text-align: center;
    margin-top: 40px;
    color: #232323;
    font-size: 34px;
    font-weight: bold;
    letter-spacing: 0;
    line-height: 41px;
}

.sos-content-edit {
    padding-top: 20px;
}

.sos-checkbox {
    margin-left: 11px;
}

.hommeexisting-img-edit {
    position: absolute;
    top: 30px;
    margin-left: -21px;
}

.normalborder {
    border: 1px solid #e0e0e0;
    width: 100%;
    max-width: 206px;
    background-color: #ffffff;
}

.account-balance {
    color: #232323;
    font-family: Lato;
    font-size: 18px;
    font-weight: bold;
    letter-spacing: 0;
    line-height: 22px;
}

.payment-card-text {
    margin-left: 4px;
}

@media only screen and (max-width: 320px) {
    label.label-check.font-weight-light {
        font-size: 14px;
        max-width: 201px;
    }
}


.disabled-label {
    color: rgb(175, 175, 175);
    opacity: 0.6;
}
