.reply-header {
  /*margin-left: 17px;*/
}
.msg-input {
  margin-right: 35px;
}
.send-btn {
  width: 121px !important;
}
.error-message {
  color: red;
  margin-left: 16%;
}
