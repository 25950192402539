.customeToolTip div {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 150px;
  height: 53px;
  border-radius: unset !important;
  margin-left: 20px;
  font-size: 14px;
}
.customeToolTip .arrow {
  z-index: 99;
  bottom: 3px;
  left: 35% !important;
  border: 10px solid rgba(0, 0, 0, 0.2);
  border-color: transparent transparent #000000 #000000;
  transform: rotate(-46deg) !important;
  box-shadow: -3px 3px 3px 0 rgb(0 0 0 / 20%);
  height: 5px !important;
  width: 5px !important;
}
.send-icon div {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 150px;
  height: 53px;
  border-radius: unset !important;
  margin-left: 20px;
  font-size: 14px;
}
.send-icon .tooltip-inner  {
  padding: 2.25rem .5rem;
}
.send-icon .arrow {
  z-index: 99;
  bottom: 3px;
  left: 35% !important;
  border: 10px solid rgba(0, 0, 0, 0.2);
  border-color: transparent transparent #000000 #000000;
  transform: rotate(-46deg) !important;
  box-shadow: -3px 3px 3px 0 rgb(0 0 0 / 20%);
  height: 5px !important;
  width: 5px !important;
}