.custom-radio {
  cursor: pointer;
}

.custom-radio .hide {
  display: none;
}

.custom-radio {
  position: relative;
  padding-left: 20px;
  margin-bottom: 15px;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
.custom-radio input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
}
.custom-radio-checked {
  position: absolute;
  top: 0;
  left: 0;

  background-color: white;
  height: 19px;
  width: 19px;
  border: 1px solid gray;
  border-radius: 50%;
}

.custom-radio input:checked ~ .custom-radio-checked {
  background-color: white;
  height: 19px;
  width: 19px;
  border: 1px solid gray;
}

.custom-radio-checked:after {
  content: "";
  position: absolute;
  display: none;
}

.custom-radio input:checked ~ .custom-radio-checked:after {
  display: block;
}

.custom-radio .custom-radio-checked:after {
  top: 2px;
  left: 2px;
  width: 13px;
  height: 13px;
  border-radius: 50%;
  background: #6e86c4;
}
.custom-radio input:checked ~ .custom-radio-checked::before {
  background-color: white;
  height: 19px;
  width: 19px;
  border: 1px solid gray;
}

/* .custom-radio-input input[type="radio"] + .fa-dot-circle-o:before,
.custom-radio-input input[type="radio"] + label > .fa-dot-circle-o:before {
  content: "\f10c";
}

.custom-radio-input input[type="radio"]:checked + .fa-dot-circle-o:before,
.custom-radio-input
  input[type="radio"]:checked
  + label
  > .fa-dot-circle-o:before {
  content: "\f192";
  color: var(--theme-color-1);
} */
