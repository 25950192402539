/* this makes the scroll bars always visible, even in OSX */
.upload-doc-scroll::-webkit-scrollbar {
    -webkit-appearance: none;
    width: 8px;
}

.upload-doc-scroll::-webkit-scrollbar-thumb {
    border-radius: 4px;
    background-color: rgba(0, 0, 0, 0.5);
    --webkit-box-shadow: 0 0 1px rgba(255, 255, 255, 0.5);
}

.upload-doc-scroll {
    overflow: scroll;
    overflow-wrap: break-word;
    height: 165px;
}

.amount-input {
    width: 35% !important;
}

.amount-label {
    width: 28% !important;
    color: #6e86c4 !important;
    font-size: 15px !important;
}

.message-received {
    display: flex;
}

.message-received-date {
    margin-right: 6px;
    color: #232323;
    font-family: Lato;
    font-size: 12px;
    font-style: italic;
    letter-spacing: 0;
    line-height: 15px;
}

.message-received-time {
    margin-left: 6px;
    color: #232323;
    font-family: Lato;
    font-size: 12px;
    font-style: italic;
    letter-spacing: 0;
    line-height: 15px;
}

.from-office {
    color: #232323;
    font-family: Lato;
    font-size: 16px;
    letter-spacing: 0;
    line-height: 19px;
}

.close-icon-message {
    margin-left: 6px;
    height: 17px;
    width: 15px;
    color: #232323;
    font-family: "SS Gizmo";
    font-size: 14px;
    letter-spacing: 0;
    line-height: 19px;
}

.signRequired {
    padding: 5px 0px 5px 0px;
}

.messages-scroll {
    max-height: 390px;
}

.float-right-inside-flex {
    margin-left: auto;
}

.doc-intake {
    /*overflow: scroll;*/
    overflow-wrap: anywhere;
    /*overflow-y: scroll;*/
}

.doc-intake::-webkit-scrollbar-thumb {
    border-radius: 4px;
    background-color: rgba(0, 0, 0, 0.5);
    --webkit-box-shadow: 0 0 1px rgba(255, 255, 255, 0.5);
}

.doc-intake::-webkit-scrollbar {
    -webkit-appearance: none;
    width: 8px;
}


.mr-16 {
    margin-right: 16px;
}

.cross-btn {
    position: absolute;
    right: 8px;
    top: 0;
    margin: 8px;
    cursor: pointer;
    z-index: 999;
}

.provider-style {
    font-size: 12px;
    font-style: italic;
}

.text-below-icons {
    font-size: 14px;
    background-color: transparent;
    background-repeat: no-repeat;
    border: none;
    cursor: pointer;
    overflow: hidden;
    outline: none;
    color: #6e86c4;
    text-decoration: underline;
}
