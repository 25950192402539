* {
    outline: none !important;
    box-sizing: border-box;
}

a:hover {
    cursor: pointer;
}

ul,
ol {
    padding: 0px;
    margin: 0px;
}

ul li,
ol li {
    list-style-type: none;
    margin: 0;
    padding: 0;
}

p,
h1,
h2,
h3,
h4,
h5,
h6 {
    padding: 0;
    margin: 0;
}

a,
a:hover,
a:focus,
a:active {
    outline: none !important;
    text-decoration: none;
}

body {
    font-family: "Lato", sans-serif;
    font-size: 14px;
    background-color: #e2e3e8;
    padding: 43px 0px;
}

img {
    max-width: 100%;
    display: block;
}

/* login page */
.sos-card {
    max-width: 409px;
    width: 100%;
    margin: auto;
    background-color: #ffff;
    padding: 15px 40px 40px 40px;
    box-shadow: 1px 2px 23px #a7a7a7;
}

.logo {
    padding-bottom: 15px;
}

.sos-content {
    padding-top: 55px;
}

.soscontent-background {
    width: 90%;
    /*padding: 55px 55px 0px 55px;*/
    padding: 1em 1em 0 1em;
    background: #f5f6fa;
}

.soscontent-mobileview {
    margin: auto;
    /*margin-left: 142px;*/
}

@media (max-width: 767px) {
    .soscontent-mobileview {
        flex-wrap: wrap;
        padding-top: 0 !important;
    }

    .homeexisting-patient {
        margin: 10px;
    }

    .homeexisting-patient-box {
        margin-left: 0 !important;
        margin-right: 0 !important;
    }
}

.text-cerulean {
    color: #6e86c4;
    font-weight: normal;
    font-size: 14px;
    line-height: 17px;
    padding-right: 10px;
}

.sos-content label {
    font-size: 20px;
    line-height: 24px;
    font-weight: normal;
    color: #232323;
    margin-bottom: 7px;
}

.sos-content input::placeholder {
    color: #8d8a8a !important;
    font-size: 14px;
    letter-spacing: -0.5px;
    line-height: 20px;
    opacity: unset;
}

.sos-content .form-control {
    padding: 7px 8px;
    border-radius: 0;
}

.extra-spacing {
    margin-bottom: 86px;
}

.text-cerulean-position {
    position: initial;
}

.remainig-days {
    color: #6e86c4;
    font-family: Lato;
    font-size: 14px;
    letter-spacing: 0;
    line-height: 17px;
    margin-left: 11px;
}

.jumbotron-transaction {
    padding: 0 17px 19px 17px;
}

.sos-custom-btn {
    font-size: 14px;
    line-height: 17px;
    font-weight: normal;
    padding: 11px 16px;
    max-width: 147px;
    width: 100%;
    transition: 0.5s;
}

.fontsize18 {
    font-size: 18px;
    line-height: 20px;
}

.sos-custom-btn-new-message {
    font-size: 14px;
    /*line-height: 17px;*/
    font-weight: normal;
    padding: 9px 0 9px 8px;
    /*padding: 11px 16px*/
    transition: 0.5s;
}

.sos-custom-btn-new-message:hover {
    cursor: pointer;
}

.sos-custom-btn-table {
    padding: 8px 8px !important;
    width: auto !important;
}

.sos-custom-btn:hover .css-15llytj span {
    background-color: #6e86c4;
}

.sos-mack-btn:hover .css-15llytj span {
    background-color: #6e86c4;
}

.dropzone-loader:hover .css-15llytj span {
    background-color: #6e86c4;
}

.sos-content .Standard-text {
    padding: 7px 0px 0px;
}

.getcode-btn {
    margin-bottom: 40px;
}

.submit-btn {
    margin: 40px 0px;
}

.save-btn {
    padding: 40px 0px 40px;
}

.reset-link-btn {
    margin: 30px 0px 0px;
}

/* home-patient-box */
.home-patient-box {
    max-width: 1107px;
    width: 100%;
    margin: auto;
    background-color: #ffff;
    padding: 15px 40px 40px 40px;
    box-shadow: 1px 2px 23px 0 #a7a7a7;
}

.header-admin {
    font-size: 18px;
    line-height: 22px;
    letter-spacing: normal;
    font-weight: normal;
    text-align: end;
}

.header-admin span {
    color: #232323;
}

.header-admin a {
    padding: 0px 20px;
}

.header {
    font-size: 18px;
    line-height: 22px;
    letter-spacing: normal;
    font-weight: normal;
    text-align: end;
}

.header span {
    color: #232323;
}

.header a {
    padding: 0px 20px;
}

.homeexisting-patient-box {
    width: 50%;
    --bs-gutter-x: 2.5rem;
    --bs-gutter-y: 0;
}

.account-card {
    max-width: 400px !important;
    width: 100%;
    margin: 0 20px;
    background-color: #ffff;
    padding: 20px 30px 20px 20px;
    box-shadow: 1px 1px 23px #a7a7a7;
    margin-bottom: 30px;
    /* min-height: 300px; */
    /* height: max-content; */
}

.account-card-shadow {
    /*max-width: 400px !important;*/
    width: 100%;
    /*margin: 0 20px;*/
    background-color: #ffff;
    /*padding: 20px 30px 20px 20px;*/
    box-shadow: 1px 1px 23px #a7a7a7;
    margin-bottom: 30px;
    /* min-height: 300px; */
    /* height: max-content; */
}

.account-balance-card {
    /* height: max-content; */
}

.hommeexisting-large-text {
    font-size: 20px;
    line-height: 24px;
    font-style: normal;
    color: #232323;
    border-bottom: 0.5px solid #e3e4e8;
}

.hommeexisting-large-text span {
    font-size: 14px;
    line-height: 17px;
    letter-spacing: normal;
    color: #232323;
    float: right;
}

.hommeexisting-img {
    margin-left: -20px;
    width: 50px;
}

.hommeexisting-large-text-inner {
    display: flex;
}

/* .hommeexisting-large-inner {
  padding-left: 78px;
} */
.hommeexisting-large-inner p {
    padding-left: 12px;
    font-size: 16px;
    color: #232323;
    line-height: 19px;
    font-weight: bold;
    margin-bottom: 0rem;
}

.hommeexisting-large-inner input::placeholder {
    color: #8d8a8a !important;
    font-size: 16px;
    letter-spacing: normal;
    line-height: 29px;
    opacity: 1;
}

.divider {
    border: 0.5px solid #e3e4e8;
    margin: 12px 0px;
}

.hommeexisting-img {
    position: absolute;
    top: 70px;
}

.homeexisting-inner-card {
    max-width: 280px;
    margin-left: auto;
}

.hommeexisting-hoiz-text {
    margin: 12px 0px;
}

.hommeexisting-large-inner-text p {
    font-size: 24px;
    line-height: 29px;
    color: #232323;
    letter-spacing: normal;
    font-weight: bold;
    padding-right: 25px;
}

.hommeexisting-large-text-inner h6 {
    font-size: 14px;
    line-height: 17px;
    letter-spacing: normal;
    color: #232323;
    z-index: 1;
}

.hommeexisting-inner-from p {
    font-size: 16px;
    color: #232323;
    line-height: 19px;
    font-weight: bold;
}

.hommeexisting-inner-from {
    justify-content: space-between;
}

.hommeexisting-inner-intake-From {
    max-width: 256px;
    margin-left: auto;
}

.hommeexisting-inner-intake-From h5 {
    font-size: 16px;
    line-height: 19px;
    color: #232323;
    font-style: normal;
}

.hommeexisting-inner-intake-From img {
    max-width: 19px;
}

.hommeexisting-inner-drag-drop {
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 86px;

    background-color: #fff;
    border: 1px dashed #979797;
    box-sizing: border-box;
}

.when-you-have-an-amo {
    /* height: 300px; */
    color: #232323;
    font-family: Lato;
    font-size: 16px;
    letter-spacing: 0;
    line-height: 20px;
    text-align: center;
    display: flex;
    justify-content: center;
    top: 45%;
    /* position: absolute; */
    padding: 25px;
    align-items: center;
}

.when-you-have-no-intake {
    height: 225px;
    color: #232323;
    font-family: Lato;
    font-size: 16px;
    letter-spacing: 0;
    line-height: 20px;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0px 70px 0px 70px;
}

.account-card-height {
    max-height: 330px !important;
}

.drag-and-drop-comple p {
    height: 30px;
    width: 310px;
    color: #232323;
    font-family: Lato;
    font-size: 14px;
    letter-spacing: 0;
    line-height: 15px;
    text-align: center;
    margin: 0px;
}

.sos-browse-btn {
    font-size: 14px;
    line-height: 17px;
    font-weight: normal;
    padding: 3px 20px;
    max-width: 200px;
    width: 100%;
    transition: 0.5s;
    margin-top: 8px;
}

.hommeexisting-mask-inner {
    max-width: 280px;
    margin-left: auto;
}

.hommeexisting-mask-inner textarea ::placeholder {
    height: 22px;
    width: 275px;
    opacity: 0.25;
    color: #8d8a8a !important;
    font-family: Lato;
    font-size: 14px;
    letter-spacing: -0.5px;
    line-height: 20px;
}

.hommeexisting-mask-inner textarea {
    overflow-x: hidden;
}

.hommeexisting-when-inner {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 330px;
    background-color: #f5f6fa;
    box-sizing: border-box;
}

.hommeexisting-when-inner p {
    color: #232323;
    font-size: 16px;
    letter-spacing: 0;
    line-height: 20px;
    text-align: center;
}

.text-style {
    margin-top: -50px;
}

.paymet-btn {
    margin-top: 10px;
}

.nav-item a {
    color: #232323;
    font-size: 18px;
    letter-spacing: 0;
    line-height: 19px;
    box-sizing: border-box;
    padding: 12px 16px !important;
    border: 3px solid transparent;
    transition: all 0.5s ease;
}

.select-card a {
    max-width: 206px;
    color: #232323;
    font-size: 20px;
    letter-spacing: 0;
    line-height: 19px;
    box-sizing: border-box;
    padding: 12px 16px !important;
    border: 3px solid transparent;
    transition: all 0.5s ease;
}

.nav-pills .nav-link.active,
.nav-pills .show > .nav-link {
    box-sizing: border-box;
    height: 118px;
    width: 151px;
    cursor: pointer;
    border: 3px solid #6e86c4;
}

.nav-item.active,
.nav-item:hover {
    box-shadow: 0 0 0 3px #6e86c4 !important;
    background-color: #ffffff !important;
    transition: all 0.1s ease;
}

.nav-item a:hover {
    color: black;
}

.payment-patient {
    max-width: 1107px;
    width: 80%;
    margin-left: auto;
    /* box-shadow: 1px 1px 6px 0 #a7a7a7; */
}

.payment-patient-inner {
    width: 100%;
    margin: 0 auto;
    background-color: #ffffff;
    box-shadow: 1px 1px 6px 0 #a7a7a7;
    padding: 20px 30px 20px 20px;
    margin-bottom: 30px;
    height: max-content;
    margin-right: 30px;
}

.payment-inner-text {
    /* max-width: 686px; */
    margin-left: auto;
}

/* .container.payment-inner-text {
  padding-left: 50px;
} */
.payment-inner-text p {
    color: #232323;
    font-size: 34px;
    font-weight: bold;
    letter-spacing: 0;
    line-height: 41px;
    /* padding-left: 50px; */
    margin-left: 60px;
}

.payment-inner-text h6 {
    color: #232323;
    font-size: 18px;
    font-weight: normal;
    letter-spacing: 0;
    line-height: 22px;
}

.payment-patient-style {
    text-align: center;
    color: #232323;
    font-size: 20px;
    letter-spacing: 0;
    line-height: 24px;
}

.payment-toggleable {
    padding-top: 15px;
}

.payment-inner-toggleable {
    margin: auto;
    max-width: 368px;
}

.payment-inner-droupdown {
    font-size: 14px;
    letter-spacing: -0.5px;
    line-height: 20px;
    border: 1px solid #94bbad;
    max-width: 184px;
    width: 100%;
    font-weight: normal;
    color: #000;
    border-radius: 0;
    text-align: left;
    position: relative;
}

.payment-inner-droupdown.dropdown-toggle::after {
    content: "\f078";
    position: absolute;
    font-family: "fontawesome";
    right: 9px;
    border: 0;
    color: #a6a1a1;
}

.payment-checkbox {
    margin-right: 3px;
    margin-top: 6px;
}

.custom-dropdown .dropdown-menu {
    max-width: 184px;
    width: 100%;
}

.sos-mack-btn {
    font-size: 14px;
    line-height: 17px;
    font-weight: normal;
    color: #fff;
    background-color: #6e86c4;
    border: 1px solid #6e86c4;
    padding: 11px 15px;
    max-width: 146px;
    width: 100%;
    transition: 0.5s;
    margin: 30px 0px 25px;
}

.sos-mack-btn:hover {
    color: #6e86c4;
    background-color: #fff;
    border: 1px solid #6e86c4;
}


.add-card-btn {
    background-color: #6e86c4;
    /* font-size: 14px; */
    font-size: 14px;
    line-height: 28px;
    font-weight: normal;
    color: #fff;
    border: 1px solid #6e86c4;
    /* padding: 11px 15px; */
    max-width: 146px;
    width: 100%;
    transition: 0.5s;
    /* margin: 30px 0px 25px; */
    margin: 10px 0px 0px;
    color: #fff;

}


.chekbox-custom a {
    box-sizing: border-box;
    height: 118px;
    min-width: 175px;
    cursor: pointer;
}

.payment-form-label {
    letter-spacing: 0;
    font-weight: 400 !important;
    color: #232323;
    font-size: 20px;
    letter-spacing: 0;
    line-height: 24px;
}

.dashboard-download-icon {
    display: grid;
    justify-content: flex-end;
}

.transaction-nav {
    padding-left: 10px;
}

/* dfdg */
@media only screen and (max-width: 1024px) {
    .soscontent-mobileview {
        /*margin-left: 40px;*/
        margin: auto;
    }

    .soscontent-background {
        width: 100%;
        padding: 55px 62px 0px 52px;
        background: #f5f6fa;
    }

    .intakeform-nav {
        padding-left: 91px;
    }
}

@media only screen and (min-width: 1024px) {
    .no-amount {
        position: absolute;
    }
}

@media only screen and (max-width: 991px) {
    .chekbox-custom a {
        width: 137px;
    }

    .nav-item a {
        padding: 12px !important;
    }

    .intakeform-nav {
        margin: 0 auto;
        display: flex;
        justify-content: center;
    }

    .soscontent-background {
        margin: 0;
        width: 100%;
        padding: 55px 4px;
        margin-left: 0;
    }

    .header-admin-email {
        position: absolute;
        top: 12px;
        right: 0px;
    }

    .header-admin-email {
        margin-top: 18px;
    }

    .logo-width {
        width: 60% !important;
    }

    .short-logo-width {
        width: 30% !important;
    }
}

@media only screen and (min-width: 767px) and (max-width: 1023px) {
    .account-card {
        margin: auto;
        margin-bottom: 30px;
    }

    .header-admin a {
        padding: 0px 5px;
    }

    .logo {
        padding-left: 0px !important;
        padding-right: 23px !important;
    }

    .navbar-content {
        margin: 0 6px;
    }

    .admin-portal-input {
        margin-top: 5px !important;
    }
}

@media only screen and (max-width: 767px) {
    .chekbox-custom a {
        width: 127px;
    }

    .payment-patient {
        width: 100%;
        margin-top: 20px;
    }

    .soscontent-background {
        margin: 0;
        width: 100%;
        padding: 55px 4px;
        margin-left: 0;
    }

    .header-admin a {
        padding: 0px 5px;
    }

    .logo {
        padding-left: 0px !important;
        padding-right: 23px !important;
    }

    .pay-inner-text p {
        margin-left: 35px;
    }
}

@media only screen and (min-width: 576px) and (max-width: 766px) {
    .admin-portal-input {
        margin-top: 5px !important;
    }
}

@media only screen and (max-width: 575px) {
    .header span {
        position: absolute;
        top: 105px;
        right: 62px;
    }

    .hommeexisting-hoiz-text span {
        display: none;
    }

    .hommeexisting-large-inner {
        padding-left: 25px;
    }

    .hommeexisting-img img {
        max-width: 10px;
    }

    .text-style {
        margin: auto;
    }

    .sos-mack {
        display: inherit !important;
    }

    .payment-patient {
        width: 100%;
    }

    .chekbox-custom a {
        box-sizing: border-box;
        height: 70px;
        width: 100px;
    }

    .home-patient-box {
        padding: 3px;
    }

    .text-cerulean {
        top: 47px;
    }

    .payment-checkbox-inner {
        display: none;
    }

    .intakeform-nav {
        /* display: flex;
        justify-content: center; */
    }

    .soscontent-background {
        margin: 0;
        width: 100%;
        padding: 55px 4px;
        margin-left: 0;
    }

    .transaction-amount {
        padding-right: 24px !important;
    }

    .hommeexisting-inner-intake-From {
        margin-left: 62px;
        max-width: 300px;
    }

    .text-logout {
        position: absolute;
        top: 32px;
        right: 0px;
    }

    .header-admin-email {
        position: unset;
        top: 0;
        right: 0px;
    }

    .admin-portal-input {
        margin-top: 5px !important;
    }

    .logo-width {
        width: 90% !important;
    }
}

@media only screen and (min-width: 414px) and (max-width: 425px) {
    .header span {
        position: absolute;
        top: 105px;
        right: 88px;
    }

    .transaction-header {
        padding-right: 0px;
    }

    .remainig-days {
        margin-left: 15px;
    }

    .payment-inner-text p {
        margin-left: 35px;
    }

    .admin-portal-input {
        margin-top: 5px !important;
    }
}

@media only screen and (max-width: 414px) {
    .payment-patient-style {
        font-size: 18px;
    }

    .chekbox-custom a {
        height: 70px;
        width: 65px;
    }

    .nav-item a {
        /* padding: unset !important; */
    }

    .intakeform-nav {
        /* display: flex;
        justify-content: center; */
    }

    .soscontent-background {
        margin: 0;
        width: 100%;
        padding: 56px 4px;
        margin-left: 0;
    }
}

/*@media only screen and (min-width: 376px) and (max-width: 411px) {*/
/*    .homeexisting-patient {*/
/*        margin-left: 42px;*/
/*    }*/
/*}*/

@media only screen and (max-width: 375px) {
    .soscontent-background {
        margin: 0;
        width: 100%;
        padding: 56px 4px 0px 44px;
        margin-left: 0;
    }

    .header span {
        position: absolute;
        top: 105px;
        right: 68px;
    }

    .recent-transaction-card {
        height: 830px;
    }

    .transaction-header {
        padding-right: 0px;
    }

    .remainig-days {
        margin-left: 15px;
    }

    .transaction-head-block {
        font-size: 15px;
    }

    .transaction-header-icon {
        margin-right: 0;
    }

    .payment-inner-text p {
        margin-left: 35px;
    }


    .inner-card-document-form {
        margin-left: 33px;
    }

    .admin-portal-input {
        margin-top: 5px !important;
    }

    .hommeexisting-mask-inner {
        margin-left: 23px;
    }
}

@media only screen and (max-width: 320px) {
    .header span {
        position: absolute;
        top: 105px;
        right: 43px;
    }

    .text-logout {
        top: 23px;
    }

    .remainig-days {
        margin-left: 15px;
    }

    .hommeexisting-inner-intake-From {
        margin-left: 28px;
    }

    .hommeexisting-large-text {
        font-size: 17px;
    }

    .transaction-head-block {
        font-size: 11px;
    }

    .transaction-header-icon {
        margin-right: 0;
    }

    .homeexisting-patient {
        margin-left: 0px;
    }


    .if-you-would-like-y {
        height: 97px !important;
        width: 168px !important;
    }


    .admin-received-msg {
        display: block !important;
    }
}

.check-btn {
    margin-top: 5px;
    margin-right: 5px;
    margin-bottom: 10px;
}

.rectangle {
    box-shadow: 0px 0px 0px 1px #e0e0e0;
    background-color: #ffffff;
    margin-bottom: 15px;
}

.nav-item-margin {
    margin-bottom: 10px;
}

.cursor-pointer {
    cursor: pointer;
}

.text-support {
    color: #6e86c4;
}

.save-payment-btn {
    background-color: #6e86c4;
    border: 1px solid #6e86c4;
    padding: 11px 15px;
    max-width: 204px;
    width: 100%;
    transition: 0.5s;
    margin: 30px 0px 25px;
    /* color: #ffffff; */
    font-size: 14px;
    letter-spacing: 0;
    line-height: 17px;
}

.save-payment-btn:hover .css-15llytj span {
    background-color: #6e86c4;
}

.font-weight-bold {
    font-weight: bold;
}

.text-logout {
    color: #6e86c4;
    font-weight: normal;
    font-size: 14px;
    line-height: 17px;
    white-space: nowrap;
    padding-right: 0 !important;
}

.message-received-text {
    color: #6e86c4;
    font-family: Lato;
    font-size: 16px;
    letter-spacing: 0;
    line-height: 19px;
}

.custom-link {
    cursor: pointer;
    color: #6e86c4;
}

.custom-link:hover {
    text-decoration: none;
    color: #0d6efd;
}

.time-out {
    display: flex;
    justify-content: center;
}

.keep-login {
    max-width: 154px !important;
}

.trans-src {
    font-family: "fontello";
    font-style: normal;
    font-weight: normal;
    speak: none;
    font-variant: normal;
    text-transform: none;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    letter-spacing: 2px;
}

.justify-center-text {
    display: flex;
    justify-content: center;
    align-items: center;
}

.message-display-line {
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
    word-wrap: break-word;
}

.message-display {
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
    word-wrap: break-word;
}

.sos-custom-btn-css {
    background-color: #6e86c4;
    color: #fff;
    border: 1px solid #6e86c4;
}

.sos-custom-btn-css:hover {
    transition: 0.5s;
    color: #6e86c4;
    border: 1px solid #6e86c4;
    background-color: #fff;
}

.sos-secondary-btn-css {
    transition: 0.5s;
    border: 1px solid #6e86c4;
    color: #6e86c4;
    background-color: #fff;
}

.sos-secondary-btn-css:hover {
    background-color: #6e86c4;
    color: #fff;
    border: 1px solid #6e86c4;
}

.sos-secondary-btn-padding {
    padding: 6px 16px;
}

.jumbotron-home-data {
    padding: 0 48px 60px 48px;
}

.creditCard .sos-content {
    padding-top: 0px;
}

.if-you-would-like-y {
    height: 80px;
    width: 223px;
    color: #232323;
    font-family: Lato;
    font-size: 16px;
    letter-spacing: 0;
    line-height: 20px;
    text-align: center;
}

.get-started-btn {
    display: flex;
    justify-content: center;
}

.get-started {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 330px;
}

.no-transaction-yet {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 450px;
}

.when-no-data {
    margin-left: 37px;
}

.auto-payment {
    margin-top: 11px;
}

.payment-method-text {
    margin-left: 4px;
    font-size: 18px;
}

.add-card-text {
    display: block;
    text-align: center;
}

.custom-popup-modal .arrow {
    z-index: 99;
    bottom: 119px;
    left: 76px !important;
    box-sizing: border-box;
    border: 1em solid rgba(0, 0, 0, 0.2);
    border-color: transparent transparent #fff #fff;
    transform: rotate(136deg) !important;
    box-shadow: -3px 3px 3px 0 rgba(0, 0, 0, 0.2);
}

.documents-drag-when-no-data {
    margin-top: 225px;
}

.documents-drag-when-data {
    margin-top: 0px;
}

.admin-received-msg {
    display: flex;
}

.horizontal-arrow {
    margin-left: 7px;
    margin-right: 7px;
}

.officeSetup-checkbox {
    margin-left: 11px;
    width: max-content;
    display: grid;
}

.officesetup-custom-checkbox .custom-checkbox {
    top: 12px;
}

.home-ammount-balance {
    height: 210px;
    overflow: scroll;
}

.clickable {
    cursor: pointer;
}

.m-l-80 {
    margin-left: 80px;
}

.m-b-20 {
    margin-bottom: 20px !important;
}


.text-align-center {
    text-align: center;
}

.flex {
    display: flex;
}

.flex-space-between {
    justify-content: space-between;
}

.m-l-10 {
    margin-left: 10px;
}

.flex-align-center {
    align-items: center;
}

.flex-end {
    justify-content: flex-end;
}

.m-l-25 {
    margin-left: 25px;
}

.column {
    flex-direction: column;
}


.flex-space-between {
    display: flex;
    justify-content: space-between;
}

.full-width {
    width: 100%;
}

.h-600 {
    height: 600px;
}

.h-500 {
    height: 500px;
}

.m-20 {
    margin: 20px !important;
}

.m-auto {
    margin: auto;
}

.gap-20 {
    gap: 20px;
}

.ellipsis-text {
    white-space: nowrap;
    /*overflow: hidden;*/
    overflow: scroll;
    max-width: 150px;
    text-overflow: ellipsis;
}


.ellipsis-text:hover {
    white-space: normal;
    overflow: scroll;
    word-break: break-all;
    /*max-width: none;*/
    text-overflow: '-';
}

.mb-7 {
    margin-bottom: 4em;
}


.credit-balance {
    color: red;
}


li.nav-item {
    height: 196px;
    width: 200px;
}

.p-10 {
    padding: 10px;
}

.space-between {
    justify-content: space-between;
}

.flex-gap-10 {
    gap: 10px;
}
.flex-gap-20 {
    gap: 20px;
}
.flex-gap-1em {
    gap: 1em;
}

.link {
    cursor: pointer;
    color: #6e86c4;
    font-size: 14px;
    font-weight: normal;
    text-decoration: underline;
}

.align-items {
    align-items: center;
}
