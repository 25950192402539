
  .continue-btn {
    width: 121px !important;
  }

  .space-around {
    justify-content: space-around;
  }

  .space-evenly {
    justify-content: space-evenly;
  }

  .flex-column {
    flex-direction: column;
  }

  .flex-center {
    justify-content: center;
  }
