#jaas-container {
    width: calc(100% - 100px);
    height: calc(100vh - 200px);
}

.view-card {
    height: calc(100vh - 100px);
    width: 100%;
    margin: auto 20px;
    background-color: #ffff;
    padding: 15px 40px 40px 40px;
    box-shadow: 1px 2px 23px #a7a7a7;
}
