.no-grow-no-shrink {
    flex-grow: 0;
    flex-shrink: 0;
}

.basis-max-10 {
    flex-basis: 10%;
    max-width: 10%;
}
.basis-max-15 {
    flex-basis: 15%;
    max-width: 15%;
}
.basis-max-20 {
    flex-basis: 20%;
    max-width: 20%;
}
.basis-max-25 {
    flex-basis: 25%;
    max-width: 25%;
}
.basis-max-30 {
    flex-basis: 30%;
    max-width: 30%;
}
.basis-max-35 {
    flex-basis: 35%;
    max-width: 35%;
}
.basis-max-40 {
    flex-basis: 40%;
    max-width: 40%;
}
.basis-max-45 {
    flex-basis: 45%;
    max-width: 45%;
}
.basis-max-50 {
    flex-basis: 50%;
    max-width: 50%;
}
.basis-max-60 {
    flex-basis: 60%;
    max-width: 60%;
}
.basis-max-70 {
    flex-basis: 70%;
    max-width: 70%;
}
.basis-max-80 {
    flex-basis: 80%;
    max-width: 80%;
}
.basis-max-90 {
    flex-basis: 90%;
    max-width: 90%;
}


.flex-end {
    justify-content: flex-end;
}

.flex-center {
    justify-content: center;
}

.font-size-18 {
    font-size: 18px;
}
