.registration-page {
  margin-top: 15px;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
}

.registration-box {
  border-radius: 20px;
  box-shadow: 0 10px 16px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  padding: 20px;
  width: 100%;
  max-width: 350px;
  height: fit-content;
}

.registration-head {
  width: 100%;
  text-align: center;
  font-size: 30px;
  margin: 0;
  text-transform: uppercase;
}

.registration-field {
  width: 100%;
  padding: 10px;
  border-radius: 5px;
  border: 1px solid rgba(0, 0, 0, 0.1);
}

.registration-field:focus {
  outline: 0;
}

.registration-btn {
  color: #fff;
  background: #000;
  border-radius: 20px;
  padding: 10px 20px;
  border: 0;
  width: 50%;
  font-size: 15px;
  text-transform: uppercase;
  cursor: pointer;
}

.registration-btn:focus {
  outline: 0;
}

.registration-already-typo {
  font-size: 12px;
  font-weight: 1000;
  color: rgba(0, 0, 0, 0.5);
  margin: 0;
}

.registration-already-typo span {
  color: rgba(0, 0, 255, 0.5);
  cursor: pointer;
  margin-left: 5px;
}

.registration-with-google-btn {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  background: #dd4b39;
}

.registration-with-google-icon {
  width: 17px;
  height: 17px;
  margin-right: 5px;
}

.registration-with-google-icon g {
  fill: #fff;
}

.registration-err-txt {
  margin-bottom: 10px;
  padding: 10px;
  font-size: 13px;
  font-weight: 500;
  color: red;
  background: rgba(255, 0, 0, 0.1);
}

.registration-field-err-txt {
  text-align: left;
  font-family: "geomanistlight";
  color: #f21c1c;
  font-size: 16px;
  padding: 0;
  margin-bottom: 5px;
}

.clickable {
  cursor: pointer;
}

.registration-err-txt-div {
  width: 100%;
  margin-bottom: 10px;
}
