.popOverCustome .custom-popover-button {
    font-size: 14px;
    line-height: 17px;
    font-weight: normal;
    padding: 10px 10px;
    transition: 0.5s;
    margin-top: 8px;
  }
  .popover-head {
    background-color: white;
    border-bottom: 0px;
  }
  .popover {
    max-width: 185px !important;
    padding: 20px 10px;
    box-shadow: 1px 1px 6px 0 #a7a7a7;
    border: unset !important;
    border-radius: 0 !important;
  }
  .popOverCustome .arrow {
    z-index: 99;
    bottom: 103px;
    left: 76px !important;
    box-sizing: border-box;
    border: 1em solid rgba(0, 0, 0, 0.2);
    border-color: transparent transparent #fff #fff;
    transform: rotate(136deg) !important;
    box-shadow: -3px 3px 3px 0 rgba(0, 0, 0, 0.2);
  }
  .popover-header {
    padding: 0 !important;
    font-size: 14px !important;
    line-height: 17px;
  }
  .popover-body {
    padding: 0 !important;
  }
  
  .close-btn {
    margin-right: 5px;
  }
  
  .popover-button {
    display: flex;
    justify-content: center;
  }


.modal-body-text-size {
  font-size: 16px;
}

.modal-title {
  text-align: center;
  margin-bottom: 5vh;
}
.modal-text {
  font-family: "Lato";
  padding: 48px 0px;
}

.modal-table {
  width: 88%;
  margin-left: 6%;
  margin-right: 6%;
}

.border-top {
  border-top: 1pt solid black;
}

.cell-padding {
  padding: 1em 0 0 0;
}
