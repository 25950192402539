.prepare-document-container {
    background: white;
    user-select: none;
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    z-index: 10000;
}


.document-container {
    background: white;
    position: fixed;
    display: flex;
    top: 60px;
    left: 0;
    width: 100vw;
    height: calc(100vh - 60px);
}

.header {
    position: fixed;
    left: 0;
    width: 100vw;
    top: 0;
    width: 100%;
    height: 60px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 20px;
    background-color: rgb(238 238 238);
}

.panel-container {
    padding: 20px;
}

.panel-submit {

}

.viewer-container {
    width: 100%;
    height: calc(100vh - 200px);
}

.viewer-container .webviewer {
    height: 100%;
    width: 100%;
}

.panel-text {
    color: rgb(85, 93, 101);
    padding: 16px 0px 8px;
    font-size: 14px;
}

.field:hover {
    color: white;
    background: #6e86c4;
}

.field {
    padding: 10px;
    cursor: pointer;
    border: 1px solid #6e86c4;
    color: rgb(85, 93, 101);
}

.so-btn {
    min-width: 100px;
}

.panel-fields {
    display: flex;
    flex-direction: column;
    gap: 10px;
}

.header-action {
    display: flex;
    align-content: center;
    gap: 20px;
}
