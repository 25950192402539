.toast-container {
  width: 100%;
  box-sizing: border-box;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  padding: 10px 0;
}

.toast-bar {
  width: 100%;
  box-sizing: border-box;
  max-width: 500px;
  font-size: 13px;
  font-weight: 500;
  padding: 10px;
  margin: 0;
}

.toast-bar span {
  padding: 0 5px;
  margin-left: 10px;
  float: right;
  background: white;
  border-radius: 50%;
  cursor: pointer;
}

.toast-bar-success {
  color: green;
  background: rgba(0, 255, 0, 0.1);
}

.toast-bar-danger {
  color: red;
  background: rgba(255, 0, 0, 0.1);
}
