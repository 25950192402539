.color-text {
  color: red !important;
}
.btn-width-reset {
  max-width: 210px !important;
}
.content-design {
  color: #6e86c4 !important ;
  font-size: 13px !important;
}
.content-design {
  color: #6e86c4 !important ;
  font-size: 13px !important;
}
